import HeroSection from "@/components/blogs/allBlogs/HeroSection";
import WeddingDate from "@/containers/Homepage";
import WeddingFooter from "@/components/WeddingFooter";
import Header from "@/components/Header"; 
import { getBackendUrl } from "@/env";
import { Stack } from "@mantine/core";
import { GetServerSideProps, GetServerSidePropsContext } from "next";
import Head from "next/head";
import Error from "next/error";
import "bootstrap/dist/css/bootstrap.min.css";
// import "@/styles/sass/main.scss";

export default function Home() {
  return (

    
    <>
      <Head>
        <title>
          Uthsav - Wedding planning | Event planning
        </title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <meta
          name="description"
          content="Uthsav - Wedding planning | Event planning"
        />
      </Head>
      <Header />
      <Stack h="100%" gap="0px" align="center">
        {/* Wedding Date Section */}
        <WeddingDate />
      
      </Stack>
      <WeddingFooter />
    </>
  );
}

// Optional: Uncomment this if you're fetching data server-side
// export const getServerSideProps: GetServerSideProps = async ({
//   res,
// }: GetServerSidePropsContext) => {
//   try {
//     const response = await fetch(
//       `${getBackendUrl()}/blog/all/?pageNumber=1&pageSize=1&isOrderDesc=true`
//     );
//     if (!response.ok) {
//       return {
//         notFound: true,
//       };
//     }

//     const data = await response.json();

//     if (data.results.length === 0) {
//       return {
//         notFound: true,
//       };
//     }
//     res.setHeader("Cache-Control", "s-maxage=600, stale-while-revalidate");
//     return {
//       props: {
//         blogData: data.results[0],
//       },
//     };
//   } catch (error) {
//     console.error("Failed to
